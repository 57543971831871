.footer {
  padding: 80px 150px 25px;
  background: $color-black;
  color: white;
  position: relative; }

.footer__logo {
  margin-bottom: 110px;
  text-align: center;
  color: $color-red; }

.footer__row {
  font-size: 0;
  margin: 70px -20px; }

.footer__list {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  box-sizing: border-box;
  padding: 0 20px;
  @include f(16); }

.footer__list-item {
  &:not(:first-child) {
    margin-top: 15px; }
  .h4 {
    @include f(28);
    line-height: 1.3; }
  &.strong a {
    @include f(28);
    line-height: 1.3;
    font-weight: bold; }
  small {
    font-size: em(14,16); } }

.footer__copyright {
  margin-top: 140px; }

.footer__made-by {
  position: absolute;
  bottom: 36px;
  left: 100%;
  margin-left: -14px;
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  white-space: nowrap;
  @include f(14);
  a {
    text-decoration: underline; } }

