.nav-links {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  color: $color-black;
  padding: 34px 40px 0 40px;
  @include f(14);
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
  a {
    &:not(:first-child) {
      margin-left: 25px; } }
  .top-line-cc & {
    color: white; }
  .has-red-line & {
    color: white;
    a:hover {
      color: $color-black; } }
  .has-red-line.top-line-cc & {
    a:hover {
      color: red; } } }

.language {
  position: fixed;
  z-index: 9;
  top: 50%;
  right: 5px;
  @include f(10);
  font-weight: 700;
  color: $color-black;
  height: em(150,10);
  margin-top: em(-75,10);
  width: em(15,10);
  text-align: center;
  .middle-line-cc & {
    color: white; }
  .menu-open & {
    color: black;
    z-index: 12;
    .language__link {
      &:after {
        color: red; } } }
  .has-red-line & {
    color: white;
    a:hover {
      color: $color-black; } }
  .has-red-line.middle-line-cc & {
    a:hover {
      color: red; } } }

.language__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  transform-origin: center center;
  text-align: center;
  width: em(150,10); }

.language__link {
  display: inline-block;
  vertical-align: top;
  padding-bottom: 5px;
  color: inherit;
  text-decoration: none;
  position: relative;
  line-height: 1;
  margin: 0 8px;
  @include trans();
  transition-property: opacity, color;
  opacity: 0.5;
  &:hover,
  &:focus {
    color: $color-red;
    opacity: 1; }
  &.is-active {
    opacity: 1;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background: currentColor; } } }

.menu {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  overflow: hidden;
  &.is-active {
    width: 100%; } }

.menu__inner {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100px;
  right: 0;
  height: 100%;
  padding: 50px percentage(100/1400) 0 percentage(70/1400);
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  @include vertical;
  .menu.is-active & {
    overflow: auto; } }

.menu__inner-center {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 100%;
  white-space: normal;
  @include f(16); }

.menu__list-container {
  font-size: 0; }

.menu__hr {
  display: block;
  margin: 30px 0;
  height: 1px;
  background: rgba(black, 0.2);
  border: none;
  transition: all 0.5s ease;
  width: 0;
  opacity: 0;
  .menu.is-animate & {
    width: 100%;
    opacity: 1;
    transition-delay: 0.5s; } }

.menu__list {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 25%;
  min-width: 200px;
  padding-right: 2%;
  @include f(16);
  transition: all 0.3s ease;
  transform: translateX(-50px);
  opacity: 0;
  visibility: hidden;
  @for $i from 1 through 4 {
    $from-end: 4 - $i;
    &:nth-child(#{$i}) {
      transition-delay: ($from-end * 0.05) + s; } }
  .menu.is-animate & {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        transition-delay: 0.3 + ($i * 0.05) + s; } } } }

.menu__list-item {
  h4 {
    @include f(28); }
  &.strong {
    font-size: 28px;
    font-weight: bold;
    @include f(28); }
  small {
    font-size: em(14,16); }
  &:not(:first-child) {
    margin-top: 15px; } }

.menu__tiles {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.menu__tile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8.34%;
  background: white;
  transition: all 0.4s ease;
  opacity: 0;
  transform: scale(0.9);
  $tile-count: 12;
  @for $i from 1 through $tile-count {
    &:nth-child(#{$i}) {
      $from-end: $tile-count - $i;
      left: (($i - 1) * 8.33) + %;
      transition-delay: (($from-end) * 0.02) + s; } }
  .menu.is-animate & {
    opacity: 1;
    transform: scale(1);
    @for $i from 1 through $tile-count {
      &:nth-child(#{$i}) {
        transition-delay: (($i - 1) * 0.02) + s; } } } }

.menu__panel {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100px;
  cursor: pointer;
  color: white;
  .menu__tile {
    width: 100%;
    transition-delay: 0s; }
  &:hover {
    color: $color-red;
    .menu__tile {
      opacity: 1;
      transform: scale(1); }
    .menu-button:not(:hover),
    .menu-logo:not(:hover) {
      color: $color-red; } }
  .menu.is-active & {
    color: black;
    &:hover {
      color: $color-red; } } }

.menu-button {
  position: absolute;
  top: 50%;
  left: 37px;
  font-size: 0;
  margin: -12px -2px -3px 0;
  color: $color-black;
  width: 28px;
  box-sizing: border-box;
  background: none;
  @include trans(color);
  @include clr;
  &:hover {
    color: $color-red; }
  &.is-active {
    .menu-button__piece {
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        transform: translateX(-5px) scaleX(0);
        transform-origin: left; }
      &:nth-child(4) {
        transform: translateX(5px) scaleX(0); }
      &:nth-child(2) {
        transform: translateY(-2px) rotate(-45deg) scaleX(1.77); }
      &:nth-child(6) {
        transform: translateY(2px) rotate(45deg) scaleX(1.77); } } }
  .middle-line-cc & {
    &:not(:hover) {
      color: white; } }
  .menu-open & {
    &:not(:hover) {
      color: $color-black; } } }

.menu-button__piece {
  float: left;
  width: 8px;
  height: 6px;
  margin: 0 2px 3px 0;
  background: currentColor;
  transition: transform 0.3s ease;
  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(5) {
    transform-origin: left; }
  &:nth-child(4) {
    transform-origin: right; }
  &:nth-child(2) {
    transform-origin: right bottom; }
  &:nth-child(6) {
    transform-origin: right top; }
  &_wide {
    width: 16px; } }


.menu-logo {
  position: absolute;
  top: 20px;
  left: 32px;
  height: 36px;
  font-size: 0;
  color: $color-red;
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 36px;
    transition: all 0.5s ease;
    fill: currentColor;
    &:not(:first-child) {
      opacity: 0;
      visibility: hidden; }
    $pos: 0, 33, 64, 94, 126, 141, 157;
    @for $i from 1 through length($pos) {
      &:nth-child(#{$i}) {
        $val: nth($pos, $i);
        left: #{$val}px;
        transform: translateX(#{-$val}px); } }
    &.icon-letter-dot {
      transform: translateX(-122px);
      opacity: 1;
      visibility: visible; } }
  .top-line-cc &:not(:hover) {
    color: white; }
  .menu.is-active & {
    color: $color-red; }
  .menu.is-animate & {
    .icon {
      transform: translateX(0);
      opacity: 1;
      visibility: visible; } } }

.no-js .has-toparea {
  .menu-logo,
  .menu-button,
  .language,
  .nav-links {
    color: white; } }
