.parallax-logotype {
  position: absolute;
  z-index: 0;
  top: 250px;
  right: -5px;
  height: 1003px;
  width: 212px;
  font-size: 212px;
  .icon-rezult-logotype {
    position: absolute;
    top: 0;
    right: 100%;
    fill: $color-red;
    stroke: $color-red;
    transform: rotate(-90deg);
    transform-origin: right top; } }

.promo-text {
  margin: 55px 0 65px 0;
  max-width: 800px;
  .promo-text__item {
    font-size: 18px; } }

.promo-text__item {
  margin: 70px 0;
  margin: 40px 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }

.gallery {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  min-height: 500px; }

.gallery__left,
.gallery__right {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  position: relative; }

.gallery__right {
  padding-left: 130px;
  position: relative; }

.gallery__text-container {
  display: inline-block;
  vertical-align: middle;
  text-align: left; }

.gallery__text {
  max-width: 400px;
  position: absolute;
  top: 300px;
  top: 30vh;
  left: 130px;
  @include f(16,36);
  .h2 {
    margin-bottom: 40px;
    line-height: 1.2; } }

.products {
  margin: 170px 0 0; }

.products__footer {
  text-align: center;
  margin-top: 40px; }

.section {
  margin-top: 110px;
  &.section_spaced {
    margin-bottom: 50px; } }

.section-filters {
  margin: 0 0 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.section-filters__cell {
  width: 33.3%;
  text-align: center;
  &:last-child {
    text-align: right; }
  &:first-child {
    text-align: left; } }

.section-header {
  text-align: center;
  margin-bottom: 32px;
  .h2 {
    line-height: 1; }
  .mdfnav {
    margin-top: 40px; }
  &_top-margin {
    margin-top: 25px; } }


.section-header__subtitle {
  text-align: center;
  font-size: 18px;
  max-width: 800px;
  margin: 35px auto 35px auto; }

.section-header__list {
  margin-top: 20px; }

.section-header__list-item {
  display: inline-block;
  &:not(:last-child) {
    margin-right: 25px; } }

.section-header__link {
  display: inline-block;
  position: relative;
  @include f(16,28,100);
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: rgba(black, 0.4);
  @include trans;
  &:hover {
    color: $color-red; }
  &.is-active {
    color: inherit;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $color-red; } } }

.logos {
  margin-bottom: 50px; }
.logos__container {
  padding: 30px 100px;
  text-align: center;
  img {
    vertical-align: middle;
    margin-right: 30px;
    height: 40px; } }
.logos_alt {
  .logos__container {
    img {
      height: auto; } } }
.logos__wrap {
  display: flex;
  justify-content: space-between; }
.logos_center {
  .logos__wrap {
    display: block;
    text-align: center;
    img {
      display: inline-block;
      height: auto;
      width: auto; } } }
.map {
  margin: 115px 0 0; }

.map__container {
  position: relative;
  height: 520px;
  background: img-url('map.jpg') no-repeat center;
  background-size: cover; }

#map {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%; }

.map-select {
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 100px;
  // left: 50%
  // margin-left: -540px
  width: 280px;
  height: 48px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
  user-select: none;
  .icon {
    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 15px;
    margin-top: -0.55em;
    fill: currentColor;
    opacity: 1; }
  &.is-active {
    .map-select__list {
      transform: translateY(0);
      opacity: 1;
      visibility: visible; } } }

.map-select__current {
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 0 45px;
  background: none;
  text-align: left;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: black;
  @include trans(color);
  .icon-tag {
    opacity: 0; }
  .icon-tag-o {
    opacity: 0.3; }
  .icon {
    @include trans(opacity); }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -2px;
    @include arr(6,5,currentColor,b); }
  &:hover {
    color: red;
    .icon-tag {
      opacity: 1; }
    .icon-tag-o {
      opacity: 0;
      transition-delay: 0.1s; } } }

.map-select__list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease; }

.map-select__item {
  position: relative;
  height: 48px;
  line-height: 48px;
  padding: 0 10px 0 45px;
  @include text-overflow;
  cursor: pointer;
  @include trans(color);
  @include f(16);
  .icon {
    transform: translateY(-5px);
    opacity: 0;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.33, 1.66);
    transition-property: transform, opacity; }
  &:hover {
    color: red;
    .icon {
      transition-delay: 0.1s;
      transform: translateY(0);
      opacity: 1; } } }

.toparea {
  position: relative;
  z-index: 0;
  height: 600px;
  height: 80vh;
  box-sizing: border-box;
  padding-top: 23px;
  color: white;
  text-align: center;
  .l {
    position: relative;
    z-index: 1;
    height: 100%; } }

.toparea__nav-btn {
  opacity: 0.3;
  &:hover {
    opacity: 1; } }

.toparea__footer {
  position: absolute;
  padding-bottom: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  .select-buttons-group {
    margin: 0; } }

.toparea__title {
  margin: 16px 0;
  @include f(64,64);
  font-weight: bold; }

.toparea__small-subtitle {
  display: block;
  margin-bottom: 4px;
  @include f(14);
  opacity: 0.5; }

.toparea__subtitle {
  @include f(16,false,100);
  font-weight: bold;
  text-transform: uppercase; }

.toparea-gallery {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.group-title {
  display: block;
  margin-bottom: 10px;
  @include f(14); }

.select-buttons-group {
  margin-bottom: 50px;
  font-size: 0;
  .btn {
    vertical-align: middle;
    margin: 0 20px 20px 0; } }

.figure {
  margin: 50px 0 70px;
  &_with-aside {
    font-size: 0;
    .figure__img {
      display: inline-block;
      vertical-align: middle;
      width: percentage(580/780); } } }

.figure__img {
  margin-left: -100px;
  box-sizing: border-box;
  img {
    display: block;
    max-width: 100%; } }

.figure__aside {
  display: inline-block;
  vertical-align: middle;
  width: percentage(300/780);
  box-sizing: border-box;
  text-align: center;
  padding: 0 0 0 30px;
  &:first-child {
    margin-left: -100px;
    padding: 0 30px 0 0; }
  + .figure__img {
    margin-left: 0; } }

.figure__caption {
  margin-top: 70px;
  font-size: 16px; }


.number {
  position: relative;
  text-transform: uppercase;
  @include f(16, 24, 100);
  font-weight: 700;
  &:not(:first-child) {
    margin-top: 65px; }
  &_small {
    .number__value {
      font-size: 60px; } } }

.number__value {
  display: block;
  font-size: 90px;
  line-height: 1;
  letter-spacing: 0; }


.v-line-container {
  position: relative;
  .l {
    position: relative;
    z-index: 1; } }

.v-line {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  margin-left: 310px;
  background: red;
  color: white;
  overflow: hidden;
  .logotype-full {
    position: absolute;
    right: 100%;
    margin-right: percentage(-100/400);
    top: 200px;
    transform-origin: right top;
    transform: rotate(-90deg);
    font-size: 60px; } }

.step {
  position: relative;
  margin: 180px 0;
  @include f(16,24);
  &:last-child {
    margin-bottom: 0; }
  p {
    margin: 1em 0; }
  &_simple {
    padding-top: 40px;
    margin: 0;
    .step__number {
      top: 0; } }
  &.is-active {
    .step__number {
      color: red;
      text-shadow: 0 10px 30px rgba(red, 0.5); } } }

.step__text {
  position: relative;
  width: percentage(355/780);
  box-sizing: border-box; }

.step__img {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  width: percentage(415/780);
  box-sizing: border-box;
  img {
    display: block;
    max-width: 100%;
    &[src*="step-1"] {
      max-width: 115%; } } }

.step__number {
  position: absolute;
  z-index: -1;
  top: -67px;
  left: -48px;
  min-width: 80px;
  text-align: center;
  @include f(140,140);
  font-weight: 700;
  color: $color-global-bg;
  text-shadow: 1px 1px 0 red, -1px -1px 0 red, -1px 1px 0 red, 1px -1px 0 red; }

.figure-small {
  width: 400px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  img {
    display: block;
    max-width: 100%; } }

.contacts {
  position: relative;
  margin-top: 87px;
  @include clr;
  .h1 {
    margin-bottom: 35px; } }

.contacts__left {
  float: left;
  width: 50%;
  padding-bottom: 80px;
  box-sizing: border-box; }

.contacts__right {
  float: right;
  width: 50%;
  padding-left: 30px;
  box-sizing: border-box;
  &.bg {
    position: fixed;
    background: url(../img/contacts.jpg) no-repeat 50% 0;
    background-size: cover;
    top: 58px;
    * {
      display: none; } } }

.contacts__head {
  padding-bottom: 25px;
  border-bottom: 1px solid #E0E4E9;
  @include f(14,24);
  .h1 {
    margin-bottom: 14px; }
  p {
    margin: 1em 0; }
  .select {
    margin: 10px 18px 0 0; } }

.contacts__section {
  &:not(:first-child) {
    margin-top: 60px; } }

.contatcs__section-head {
  margin-bottom: 30px;
  .select {
    margin-top: 10px; } }

.contacts-list {
  height: calc(100vh - 275px);
  overflow: hidden;
  margin-bottom: -80px;
  position: relative;
  @include smooth-scroll;
  @include scrollbar {
    width: 2px;
    background: white; }
  @include scrollbar-thumb {
    width: 2px;
    background: $color-red; } }

.contacts-item {
  @include clr;
  &_hover {
    cursor: pointer;
    &:hover {
      background: #F7F9FB; } }
  &_right {
    padding-left: 50px;
    .contacts-item__inner {
      float: none; } } }


.contacts-item__inner {
  position: relative;
  float: right;
  width: 100%;
  max-width: 495px;
  box-sizing: border-box;
  padding-right: 40px;
  .contacts__right & {
    float: left;
    padding-left: 50px; }
  .h3 {
    padding: 10px 0; }
  h4 {
    padding: 30px 0 0 0;
    margin: 0; } }

.contact {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  @include f(14,24);
  p {
    margin: 0.8em 0;
    &:first-child {
      margin-top: 0; }
    &:last-child {
      margin-bottom: 0; } }
  dt {
    display: inline;
    color: #9FA5AC; }
  dd {
    display: inline; }
  &_action {
    cursor: pointer; }
  &:hover {
    background: #F7F9FB;
    .contact__actions {
      opacity: 1;
      visibility: visible; } } }

.contact__title {
  display: block;
  @include f(16,24);
  + p {
    margin-top: 0; } }

.contact__pic {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 10px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid black;
  box-sizing: border-box;
  overflow: hidden;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%; } }

.contact__actions {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -10px;
  user-select: none; }

.contact__action-btn {
  display: inline-block;
  vertical-align: middle;
  color: #DBE1E7;
  @include f(20);
  line-height: 1;
  @include trans(color);
  margin-left: 22px;
  .icon {
    fill: currentColor; }
  &:hover {
    color: red; } }

#contacts-map {
  width: 100%;
  height: 100%; }

.hero-picture {
  position: relative;
  background-size: cover;
  background-position: center;
  &:before {
    content: "";
    display: block;
    padding-bottom: percentage(300/1240); } }

.preview {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 40px 0;
  img {
    display: block;
    max-width: 100%; } }

.preview__icon {
  position: absolute;
  top: 10px;
  right: 10px;
  .icon {
    fill: white;
    font-size: 38px; } }

.features-block {
  margin: 60px 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }


.features-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    display: inline-block;
    vertical-align: middle; }
  .icon {
    font-size: 36px;
    vertical-align: top; } }
