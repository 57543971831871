@import "../../../node_modules/nouislider/src/nouislider";
// @import ../../../node_modules/nouislider/src/nouislider.pips

.noUi-horizontal {
  height: 1px;
  .noUi-handle {
    width: 49px;
    height: 58px;
    left: -25px;
    top: -15px; } }

.noUi-background {
  background: red; }

.noUi-target {
  border: none;
  box-shadow: none;
  border-radius: 0; }

.noUi-origin {
  border-radius: 0; }

.noUi-handle {
  border: none;
  box-shadow: none;
  width: 49px;
  height: 58px;
  cursor: pointer;
  background: none;
  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include trans; }
  &:before {
    background-image: url($inline-handle); }
  &:after {
    opacity: 0;
    background-image: url($inline-handle-active); }
  .slider.is-active & {
    cursor: grab;
    &:before {
      opacity: 0; }
    &:after {
      opacity: 1; } } }
