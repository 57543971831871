.modal-video {
  display: none;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  perspective: 1000px; }

.modal-video__content {
  height: 100%;
  min-width: $min-width;
  overflow: hidden; }
.modal-video__preloader {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: rgba(#000,.8);
  text-align: center;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); } }
.modal-video__container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  video {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-height: 100%;
    min-width: 100%; } }

.modal-video__overlay {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.7; }

.video-section {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: $min-width;
  padding-bottom: 70px;
  height: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  @include vertical; }

.video-section__center {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  box-sizing: border-box;
  width: 100%;
  max-width: $min-width;
  @include f(16); }

.controll-btn {
  color: white;
  background: none;
  line-height: 1;
  @include trans;
  .icon {
    vertical-align: top;
    fill: currentColor; }
  &:hover {
    color: $color-red; } }

.controll-btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
  @include f(30); }

.controll-btn-volume {
  position: absolute;
  bottom: 20px;
  right: 26px;
  @include f(24);
  padding-right: 11px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 2px;
    height: 8px;
    background: currentColor;
    margin-top: -4px;
    transition: all 0.2s ease;
    transition-property: transform, opacity, visibility; }
  &:before {
    right: 5px; }
  &:after {
    right: 0;
    transform: scaleY(1.75); }

  &.level-0,
  &.is-muted {
    &:before {
      transform: translateX(4px) rotate(45deg) scaleY(1.7); }
    &:after {
      transform: translateX(-1px) rotate(-45deg) scaleY(1.7); } }

  &.level-1 {
    &:after {
      opacity: 0;
      visibility: hidden; } }

  &.level-2 {
    &:after {
      transform: scaleY(1.75); } } }


.step-1 {
  margin-top: 10vh;
  .icon {
    fill: white; }
  .icon-r-logo {
    fill: none;
    stroke: white;
    stroke-width: 3px;
    @include f(220); }
  .icon-mouse {
    margin-top: 52px;
    @include f(52);
    path {
      fill: inherit; } }
  p {
    @include f(18);
    font-weight: 700;
    margin: 1em 0; } }

.step-1__logo {
  display: inline-block;
  position: relative;
  margin-bottom: 122px;
  .h3 {
    text-align: left;
    position: absolute;
    left: 100%;
    bottom: 8px;
    margin-left: 22px;
    line-height: 1; } }

.step-2 {
  margin-top: -8vh;
  .icon {
    fill: white; }
  .step-r-logo {
    fill: none;
    margin-bottom: 75px; }
  .h4 {
    @include f(28); } }

.step-figures-container {
  font-size: 0;
  margin-top: 100px; }

.step-figure {
  display: inline-block;
  vertical-align: middle;
  @include f(12,24,100);
  text-transform: uppercase;
  max-width: 340px;
  padding: 0 20px;
  box-sizing: border-box;
  > .icon {
    display: block;
    margin: 0 auto 50px;
    fill: white;
    @include f(80);
    min-height: 100px;
    path {
      fill: inherit; } }
  .h5 {
    display: inline-block; }
  .icon-wood-log {
    @include f(84); }
  .icon-dust {
    @include f(100); }
  .icon-radio {
    @include f(83);
    path {
      fill: inherit; } }
  .icon-wood {
    @include f(147); }
  .icon-kg {
    @include f(151); }
  .icon-wood-samples {
    @include f(152); }
  .icon-loader {
    @include f(122); } }

.step-action-button {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  position: relative;
  width: 250px;
  height: 250px;
  border: 3px solid white;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  @include vertical;
  cursor: pointer;
  @include gpu;
  .icon-do {
    position: absolute;
    font-size: 45px;
    top: 55px;
    left: 12px;
    fill: white; }
  &:hover {
    .step-action-button__tiles {
      > div {
        transform: scale(1);
        opacity: 1; } }
    .step-action-button__border {
      fill: $color-red; } }
  &:active {
    .step-action-button__tiles {
      transform: scale(0.85); } }
  &_square {
    border-radius: 0;
    width: 290px;
    border: none;
    .step-action-button__tiles {
      border-radius: 0; }
    .step-action-button__inner {
      margin-left: -30px; } } }

.step-action-button__border {
  position: absolute;
  font-size: 249px;
  top: 0;
  left: 0;
  fill: rgba(white, 0);
  stroke: white;
  @include trans; }

.step-action-button__tiles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  transition: transform 0.2s ease;
  > div {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 33.35%;
    height: 100%;
    background: $color-red;
    transition: all 0.2s ease;
    opacity: 0;
    transform: scale(0.85);
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        left: (($i - 1) * 33.34) + %;
        transition-delay: (($i - 1) * 0.05) + s; } } } }

.step-action-button__inner {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  @include f(12,24,100);
  .icon {
    display: block;
    margin: 0 auto 30px;
    fill: white;
    @include f(83); }
  .icon-radio {
    position: relative;
    right: -8px;
    margin-bottom: 20px; }
  .icon-squares {
    @include f(56); }
  .h5 {
    @include f(14); } }

.step-r-logo {
  display: block;
  margin: auto;
  @include f(100);
  fill: none;
  stroke: white;
  stroke-width: 4px; }

.step-3 {
  @include clr;
  text-align: left;
  max-width: 980px;
  margin: -8vh auto 0;
  .step-r-logo {
    display: block;
    margin: 0 auto 160px; }
  .h4 {
    margin-bottom: 1em;
    line-height: 1.2;
    @include f(28); }
  &__left {
    float: left;
    width: 48%;
    max-width: 300px; }
  &__right {
    float: right;
    width: 52%;
    padding-top: 10px;
    white-space: nowrap;
    .h5 {
      display: inline-block;
      vertical-align: middle;
      @include f(14); } } }

.step-3__circle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 65px;
  width: 210px;
  height: 210px;
  border-radius: 50%; }

.step-3__circle-border {
  position: absolute;
  z-index: 0;
  top: 0;
  left: -1px;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px dashed white;
  transition: opacity 0.4s ease;
  .is-dragging & {
    opacity: 0; } }

.step-3__slider {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -41px 0 0 -41px;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include trans;
  > * {
    pointer-events: none; }
  .icon {
    fill: white;
    font-size: 25px;
    animation: arrow-move 0.5s ease infinite alternate; }
  &:hover {
    .step-3__inner-circle {
      stroke-width: 6px;
      stroke: white; } }
  &:active {
    transform: scale(0.8);
    background: white;
    .step-3__inner-circle {
      stroke-width: 0; } }
  .is-dragging & {
    transition-duration: 0.2s; } }


.step-3__inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke-width: 2px;
  stroke: rgba(white, 0.2);
  fill: rgba(white, 0);
  @include trans; }

@keyframes arrow-move {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(8px); } }


.step-4 {
  .step-r-logo {
    margin-bottom: 55px; }
  .step-figure {
    vertical-align: top;
    position: relative;
    &:last-child {
      top: -40px; }
    > .icon {
      min-height: 152px; } }
  .step-figures-container {
    margin-top: 110px; }
  .step-action-button__inner {
    margin-top: 90px; }
  .h4 {
    line-height: 1.2; } }

.step-5 {
  .step-r-logo {
    margin-bottom: 55px; }
  .step-figure {
    vertical-align: top;
    > .icon {
      min-height: 152px; }
    &:last-child {
      position: relative;
      top: -15px; } } }

.video-timeline {
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 110px;
  right: 110px;
  //right: 83px
  height: 2px;
  padding: 2px 0;
  box-sizing: content-box;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    margin-top: -1px;
    background: rgba(white, 0.15); } }

.video-timeline__progress-bar {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 0;
  background: $color-red;
  transition: width 0.5s linear;
  max-width: 100% !important; }

.video-timeline__points {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 0;
  pointer-events: none; }

.video-timeline__point {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin: -13px 0 0 -13px;
  cursor: pointer;
  pointer-events: all;
  @include trans;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    margin: -3px 0 0 -3px;
    border-radius: 50%;
    background: $color-red;
    box-shadow: 0 0 0 2px black;
    @include trans; }
  .tooltip {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(0.6);
    left: 50%;
    bottom: 50%;
    margin-bottom: 10px; }
  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) scale(1); }
    &:before {
      box-shadow: 0 0 0 10px rgba(white, 0.2); } }
  &:first-child {
    left: 0;
    .tooltip {
      left: 50%;
      transform: scale(0.6); }
    &:hover {
      .tooltip {
        transform: scale(1); } } }
  &:nth-child(2) {
    left: 25%; }
  &:nth-child(3) {
    left: 50%; }
  &:nth-child(4) {
    left: 75%; }
  &:last-child {
    left: 100%;
    .tooltip {
      left: auto;
      right: 50%;
      transform: scale(0.6); }
    &:hover {
      .tooltip {
        transform: scale(1); } } }
  &.is-active {
    &:before {
      background: white;
      box-shadow: 0 0 0 2px white; } } }
