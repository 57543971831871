.hero {
  height: 100vh;
  position: relative;
  color: white;
  background: $color-global-bg;
  .gs {
    height: 100%; } }
.hero__scroll {
  // background: red
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 20;
  // background: url($inline-scroll) no-repeat 0 0
  width: 14px;
  height: 16px;
  margin-left: -7px;
  width: 40px;
  margin-left: 0; }
.hero__gallery {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none; }

.hero__inner {
  padding-top: 40px;
  padding-bottom: 110px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  @include vertical;
  white-space: nowrap; }

.hero__center {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  text-align: left;
  @include f(16); }

.hero__logo {
  cursor: pointer;
  .icon {
    @include f(212);
    transform: translate(0.5px, 0.5px);
    stroke: white; }
  svg path, .icon {
    fill: rgba(white, 0);
    @include trans;
    transition-property: fill, stroke; }
  &:hover {
    svg path, .icon {
      fill: $color-red;
      stroke: $color-red; } } }
.hero__subtitle {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px; }
.hero__title {
  margin-top: 40px;
  @include f(24,36,20);
  text-transform: uppercase;
  max-width: em(620,24);
  font-size: 40px;
  font-family: Arial,sans-serif;
  line-height: 42px;
  strong {
    font-weight: bold;
    color: #fc0d1b; } }

.hero__buttons {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  .btn {
    margin: 0 5px; } }
