html {
  font-size: 62.5%; }

body {
  background: $color-global-bg;
  font: normal 16px/1.4 Arial;
  font-size: 1.6rem;
  @include smooth-font;
  min-width: $min-width;
  color: black;
  &.is-dragging {
    * {
      user-select: none !important;
      cursor: grab !important; } } }

h1:not([class]), .h1 {
  @include f(36,42,100); }

h2:not([class]), .h2 {
  @include f(48); }

h3:not([class]), .h3 {
  @include f(28,24,100); }

h4:not([class]), .h4 {
  @include f(24,36);
  line-height: 1.3; }

h5:not([class]), .h5 {
  @include f(12,24,100); }

a:not([class]) {
  color: inherit;
  @include trans(color);
  text-decoration: none;
  &:hover {
    color: red; } }

.l {
  padding: 0 100px;
  box-sizing: border-box; }

.l-center {
  max-width: 1200px;
  padding: 0 100px;
  margin: auto;
  box-sizing: border-box; }

.l-col-620 {
  max-width: 620px; }

.l-catalog-item-main {
  float: left;
  width: percentage(770/1000);
  padding-top: 25px; }

.l-catalog-item-aside {
  padding-top: 25px;
  float: right;
  width: percentage(230/1000);
  position: relative;
  .calc {
    margin-top: -80px;
    margin-left: 40px; }
  @include r(1300) {
    .calc {
      margin-left: 0; } } }

.l-content {
  max-width: 990px;
  margin: auto;
  box-sizing: border-box;
  padding: 85px 210px 105px 0; }

.l-content-col {
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
  width: 50%;
  box-sizing: border-box;
  width: percentage(330/780);
  @include f(16);
  &:nth-child(odd) {
    margin-right: percentage(120/780); }
  &:last-child {
    margin-bottom: 40px; } }

.out {
  position: relative;
  overflow: hidden;
  z-index: 0; }

.clearfix {
  &:before,
  &:after {
    content: "";
    display: table; }
  &:after {
    clear: both; } }

.font-size-0 {
  font-size: 0; }

.content-wrapper {
  position: relative;
  z-index: 1; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

.spacing-100 {
  letter-spacing: 0.1em; }

.spacing-50 {
  letter-spacing: 0.05em; }

.btn {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  z-index: auto;
  background: none;
  border: 1px solid;
  color: inherit;
  height: 48px;
  padding: 0 15px;
  text-align: center;
  @include f(18, false, 20);
  line-height: 46px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  @include trans;
  user-select: none;
  appearance: none;
  > span {
    position: relative;
    z-index: 1; }
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    // transform: scale(0.5)
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 5px 35px rgba(red, 0.4);
    @include trans; }

  &:hover,
  &:focus {
    border-color: #ff0000;
    color: white;
    &:before {
      opacity: 1;
      visibility: visible;
      background: #ff0000;
      transform: scale(1); } }

  &:active {
    border-color: #f10000;
    color: white;
    &:before {
      background: #f10000; } }

  &.is-active {
    border-color: black;
    color: white;
    &:before {
      background: black;
      opacity: 1;
      visibility: visible;
      transform: scale(1); } }

  &[disabled],
  &.is-disabled {
    background: none;
    color: black;
    border-color: black;
    opacity: 0.2;
    cursor: not-allowed;
    &:before {
      display: none; } }

  &_hero {
    min-width: 303px; }

  &_lg {
    border-width: 2px;
    height: 56px;
    line-height: 54px;
    min-width: 335px;
    @include f(16, false, 25);
    text-transform: uppercase; }

  &_xlg {
    height: 64px;
    line-height: 62px;
    @include f(14,false,100);
    text-transform: uppercase; }

  &_sm {
    height: 36px;
    line-height: 34px;
    @include f(14); }

  &_fw {
    display: block;
    width: 100%; }

  &_black {
    background: black;
    color: white;
    border-color: black; }

  &_select {
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    padding: 15px 15px 0;
    box-sizing: border-box;
    border: 1px solid rgba(black, 0.5);
    @include f(12,16,100);
    text-align: left;
    text-transform: uppercase;
    &.is-selected {
      background: rgba(#C7CBCE, 0.5);
      border-color: rgba(#989DA1, 0.5);
      cursor: default;
      color: inherit;
      &:before {
        opacity: 0;
        visibility: hidden; } } }

  &_white {
    border-color: rgba(white, 0.5);
    color: white;
    &.is-selected {
      border-color: white;
      background: white;
      color: black; } }

  &_semi {
    &:not(:hover) {
      opacity: 0.5; } }

  &_tab {
    font-size: 12px;
    &.is-active {
      background: #A6ADB3;
      border-color: #A6ADB3;
      color: white;
      cursor: default;
      &:before {
        display: none; } } } }


.tab-btn-group {
  margin-bottom: 40px;
  font-size: 0;
  text-align: center;
  .btn {
    vertical-align: top; } }


.tooltip {
  display: block;
  position: absolute;
  z-index: 2;
  bottom: 100%;
  left: 0;
  max-width: 260px;
  @include f(12,18,100);
  font-weight: 700;
  line-height: 1.4;
  color: black;
  text-transform: uppercase;
  padding: 7px 20px;
  box-sizing: border-box;
  background: white;
  white-space: nowrap;
  transition: all 0.2s ease;
  transform-origin: center bottom;
  &:before {
    content: "";
    @include arr(14,10,white,tl);
    position: absolute;
    top: 100%;
    left: 50%; }
  &_arr-right {
    transform-origin: right bottom;
    &:before {
      left: auto;
      right: 0;
      @include arr(14,10,white,tr); } }
  &_arr-left {
    transform-origin: left bottom;
    &:before {
      left: 0; } } }

.tiles-group {
  @include clr;
  margin: 70px 0 90px; }

.tile {
  display: block;
  position: relative;
  z-index: 1;
  float: left;
  width: 25%;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    padding-bottom: 100%; }
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.8);
    box-shadow: 0 0 50px rgba(255, 0, 0, 0.6);
    pointer-events: none;
    @include trans;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0,0,0) scale(1.15); }
  &:hover {
    z-index: 2;
    &:after {
      transition-delay: 0.1s;
      opacity: 1;
      visibility: visible;
      transform: translate3d(0,0,0) scale(1); }
    .tile__play-button {
      animation: zoom-in 0.3s ease 1; } }
  &_2x2 {
    width: 50%; }
  &_2x1 {
    width: 50%;
    &:before {
      padding-bottom: 50%; } }
  &_full {
    width: 100%;
    &:before {
      padding-bottom: 25%;
      text-align: center; }
    .tile__video-name {
      right: 20px;
      text-align: center;
      display: block; } }
  &_center {
    .tile__video-name {
      right: 20px;
      text-align: center;
      display: block; } }

  &_1-3 {
    width: 33.33%; }
  &_2-3 {
    width: 66.66%; }
  &_2-3x1 {
    width: 66.66%;
    &:before {
      padding-bottom: 50%; } }
  &_pull-right {
    float: right; }
  &_small {
    .tile__title {
      font-size: 24px; } }
  &_simple {
    color: red;
    box-shadow: inset 0 0 0 1px red, 0 0 0 1px red;
    @include trans(color);
    &:after {
      background: red; }
    .tile__title {
      @include f(24,36);
      padding-right: 30px; }
    .tile__subtitle {
      margin-top: 3px; }
    &:hover {
      color: white;
      .tile__badge {
        color: red;
        background: white; } } }
  &_static {
    cursor: default;
    z-index: 0;
    &:after {
      display: none; } } }

.tile__inner {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    z-index: -2;
    content: ' ';
    @include g(rgba(#000,.4),rgba(#000,0)); } }

.tile__title {
  display: block;
  @include f(36,42);
  font-weight: 700;
  .h4 {
    display: inline-block;
    line-height: 1.16; } }

.tile__subtitle {
  display: block;
  @include f(14);
  font-weight: 700; }

.tile__number {
  position: absolute;
  top: 15px;
  right: 20px; }

.tile__badge {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 4px 5px 2px;
  @include f(9,9,100);
  min-width: 30px;
  text-align: center;
  box-sizing: border-box;
  color: white;
  font-weight: 700;
  background: red;
  text-transform: uppercase;
  @include trans;
  transition-property: color, background-color; }

.tile__info {
  position: absolute;
  bottom: 15px;
  left: 100%;
  margin-left: -16px;
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  white-space: nowrap; }

.tile__video-name {
  @include f(24);
  position: absolute;
  bottom: 18px;
  left: 20px; }

.tile__play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em 0 0 -0.39em;
  @include f(36);
  .icon {
    display: block;
    fill: white; } }

@keyframes zoom-in {
  0%, 100% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); } }

.select {
  display: inline-block;
  position: relative;
  z-index: 2;
  max-width: 230px;
  text-align: left;
  user-select: none;
  &.is-active {
    z-index: 5;
    .select__button-overlay {
      opacity: 1;
      visibility: visible;
      transform: none;
      color: $color-red;
      &:before {
        transform: none;
        opacity: 1;
        transition-delay: 0.2s; } }
    .select__button {
      color: $color-red;
      &:before {
        transform: translateY(10px);
        opacity: 0; }
      &:after {
        transform: scale(0, 1);
        opacity: 0; } }
    .select__list {
      opacity: 1;
      visibility: visible;
      transform: none; } }
  &.is-disabled {
    color: black;
    opacity: 0.5;
    cursor: not-allowed;
    .select__button {
      cursor: not-allowed;
      pointer-events: none;
      &:after {
        border-color: currentColor; } } }
  &_fw {
    max-width: none; } }

.select__button {
  display: block;
  position: relative;
  z-index: 1;
  background: none;
  @include f(16);
  font-weight: 700;
  max-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 15px;
  height: em(34,16);
  line-height: em(34,16);
  @include text-overflow;
  @include trans;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    @include arr(6,5,currentColor,b);
    @include trans;
    transition-property: transform, opacity;
    transform-origin: center center; }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 15px;
    border-top: 1px solid $color-red;
    margin-top: 1em;
    @include trans;
    transition-property: transform, opacity; }
  &:hover {
    color: $color-red; }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: black;
    opacity: 0.5;
    &:after {
      border-color: currentColor; } } }

.select__button-overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: -20px;
  width: 270px;
  height: 58px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #D8DBDF;
  margin-top: -29px;
  cursor: pointer;
  @include trans;
  transform: translateY(10px);
  transform-origin: 20px 50%;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -2px;
    @include arr(5,6,currentColor,l);
    @include trans;
    transform: translateX(10px);
    opacity: 0;
    transition-property: transform, opacity; } }

.select__list {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -20px;
  width: 270px;
  margin-top: em(11,16);
  padding: 15px 0;
  max-height: 300px;
  background-color: white;
  border: 1px solid #d8dbdf;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  transform: translateY(-10px);
  transform-origin: center top;
  @include smooth-scroll;
  @include trans;
  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20px;
    margin-bottom: 1px;
    @include arr(16,11,rgba(0, 0, 0, 0.15),t); }
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20px;
    margin-bottom: -1px;
    @include arr(16,11,white,t); } }

.select__item {
  @include f(16,24);
  color: black;
  cursor: pointer;
  padding: 12px 20px;
  @include trans(color);
  &:hover,
  &.is-selected {
    color: $color-red; } }

.radio-group {
  display: inline-block;
  .radio-select {
    &:not(:last-child) {
      margin-right: 25px; } } }

.radio-select {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  @include f(16);
  font-weight: 700;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    &:checked {
      + span {
        opacity: 1;
        &:after {
          transform: none;
          opacity: 1; } } } }
  span {
    opacity: 0.4;
    @include trans;
    display: inline-block;
    vertical-align: top;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $color-red;
      @include trans;
      transform: scale(0, 1);
      transform-origin: center center; } }
  &:hover {
    color: $color-red;
    span {
      opacity: 1; } } }
.h4.shift-left+p {
  margin-top: 30px; }

.text {
  @include f(16, 36);
  line-height: 1.55;
  p {
    margin: 1em 0; }
  .wp-caption {
    margin: 50px 0 70px;
    width: auto !important;
    &.aligncenter {
      img {
        margin-left: -100px;
        display: block;
        width: 100%;
        height: auto !important; } }
    &.alignleft {
      img {
        display: inline-block;
        vertical-align: middle;
        width: 74.35897%;
        height: auto;
        margin-left: -100px; }
      .wp-caption-text {
        display: inline-block;
        vertical-align: middle;
        width: 38.46154%;
        box-sizing: border-box;
        text-align: center;
        padding-left: 30px;
        margin-top: 0; } }
    &.alignright {
      width: 100%;
      display: inline-block;
      .wp-caption-text {
        display: inline-block;
        vertical-align: middle;
        width: 38.46154%;
        box-sizing: border-box;
        text-align: center;
        padding-left: 30px;
        margin-top: 0;
        float: left;
        // margin-left: -100px
        padding:  30px 30px 0 0; }

      img {
        margin-left: 0;
        display: inline-block;
        vertical-align: middle;
        width: 74.35897%;
        height: auto;
        float: right;
        margin-right: -100px; } }
    .wp-caption-text {
      font-size: 16px;
      margin-top: 70px; } }
  table {
    width: auto !important;
    border-collapse: collapse;
    td {
      padding: 10px;
      border: 1px solid #fff; } }
  ul {
    margin-left: 2em;
    list-style-type: disc; } }



%table-label {
  @include f(14,24);
  font-style: italic;
  font-weight: normal;
  color: $color-label; }

.table {
  table-layout: fixed;
  @include f(16,24);
  font-weight: 700;
  td {
    padding: 20px 0 0; }
  th {
    @extend %table-label; }
  tr {
    &:first-child {
      td {
        padding-top: 0; } } }

  &_features {
    margin-top: 70px;
    width: auto;
    min-width: 410px; }

  &_striped {
    width: calc(100% + 30px);
    margin-left: -30px;
    margin-top: 70px;
    text-align: right;
    line-height: 1.2;
    .table__caption {
      padding-left: 30px;
      &:before {
        left: 0; } }
    td, th {
      padding: 8px 5px;
      &:first-child {
        text-align: left;
        padding-left: 30px; }
      &:last-child {
        padding-right: 30px; } }
    th {
      padding-top: 0;
      padding-bottom: 12px; }
    tr {
      &:nth-child(odd) {
        td {
          background: white;
          padding-top: 8px;
          padding-bottom: 8px; } }
      &:nth-child(even) {
        td {
          padding-top: 12px;
          padding-bottom: 12px; } } } } }

.table__caption {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 33px;
  @include f(16,24,100);
  text-transform: uppercase;
  text-align: left;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -30px;
    right: 0;
    height: 1px;
    background: currentColor;
    opacity: 0.2; } }


.table__label {
  @extend %table-label;
  display: block; }


.checkbox {
  position: relative;
  display: inline-block;
  @include f(14);
  line-height: 1.5;
  color: inherit;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    &:checked + span:after {
      display: block; }
    &:disabled,
    &[disabled] {
      + span {
        color: black;
        opacity: 0.5;
        &:before {
          background: #D9E0E6;
          border-color: #ADB4BB; } } } }
  span {
    position: relative;
    display: block;
    padding-left: 27px;
    height: 20px;
    @include trans(color);
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border: 1px solid #ADB4BB;
      @include trans(border-color); }
    &:after {
      display: none;
      content: "";
      position: absolute;
      top: -10px;
      left: -9px;
      width: 38px;
      height: 35px;
      // background: url('../icons/checkmark.png')
      background: url($inline-checkmark), no-repeat center;
      background-size: contain; } }
  &:hover {
    color: red;
    span {
      &:before {
        border-color: red; } } } }


.slider {
  position: relative;
  &.is-active {
    .slider__value {
      color: red; } } }

.slider__value {
  display: block;
  text-align: center;
  margin-bottom: 13px;
  @include f(36,36);
  font-weight: 700;
  padding-left: 15px;
  @include trans(color);
  sub {
    font-size: em(18,36);
    color: #D0D5DA;
    // opacity: 0.4
    margin-left: -4px; } }

.slider__pips {
  position: relative;
  padding-top: 12px;
  margin-top: 5px; }

.slider__pip {
  position: absolute;
  top: 0;
  padding-top: 12px;
  white-space: nowrap;
  color: black;
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 1px;
    background: #B2B2B2; }
  sub {
    color: #D0D5DA;
 }    // opacity: 0.4
  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      left: ($i - 1) * 10 + %; } }
  &:last-child {
    left: auto;
    right: 0;
    text-align: right;
    &:before {
      position: absolute;
      right: 0;
      left: auto; } } }


.title-margin {
  &:not(:first-child) {
    margin-top: 2em; }
  &:not(:last-child) {
    margin-bottom: 1.7em; } }


.shift-left {
  position: relative;
  left: -100px; }

.logotype-full {
  position: relative;
  display: inline-block;
  font-size: 17px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  > span {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    letter-spacing: 0.1em; }
  .icon-rezult-logotype {
    display: inline-block;
    vertical-align: bottom;
    margin-right: em(10,60);
    font-size: em(190,60);
    fill: currentColor;
    stroke: currentColor; } }

.form-column {
  max-width: 350px; }

.form-field {
  position: relative;
  margin-top: 30px;
  width: 100%;
  perspective: 800px;
  &:hover {
    .form-hint {
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg);
      transition-delay: 0.2s; } }
  &.has-error {
    .form-input {
      color: red;
      border-color: red; }
    .form-hint-error {
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg); } }
  &_country {
    float: left;
    width: percentage(145/350);
    max-width: 145px;
    margin-right: percentage(20/350); }
  &_city {
    float: left;
    width: percentage(290/350);
    max-width: 185px; }
  &_phone {
    max-width: 245px; } }

.form-label {
  display: block;
  @include f(12,24);
  font-weight: normal;
  opacity: 0.4; }

.form-input {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 0 7px;
  @include f(16, 24);
  font-family: Arial, sans-serif;
  font-weight: normal;
  border: 1px solid;
  border-width: 0 0 1px 0;
  background: none;
  resize: vertical;
  appearance: none;
  @include trans;
  &:focus {
    border-color: red;
    box-shadow: 0 1px 0 0 red; }
  &.has-error {
    border-color: red;
    box-shadow: 0 1px 0 0 red; } }

.form-hint,
.form-hint-error {
  position: absolute;
  z-index: 0;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 7px 7px 7px 30px;
  box-sizing: border-box;
  background: #e8ebef;
  color: #acafb2;
  @include f(12, 16.5);
  transform: rotateX(-90deg);
  opacity: 0;
  visibility: hidden;
  transform-origin: center top;
  @include trans;
  .icon {
    position: absolute;
    fill: currentColor;
    top: 7px;
    left: 7px;
    @include f(16); } }

.form-hint-error {
  color: red; }

.mdfnav {
  text-align: center;
  margin-bottom: 35px;
  font-size: 16px;
  text-transform: uppercase;
  list-style: none;
  li {
    display: inline-block; }
  a {
    display: inline-block;
    font-weight: bold;
    margin: 0 20px;
    padding-bottom: 3px;
    border-bottom: 1px solid #000;
    &:hover {
      border-color: #fc0d1b; } }
  .current_page_item a {
    border-color: #fc0d1b; } }

.filtered-tiles {
  @include clr; }

.filtered-tiles__main {
  float: left;
  width: 75%; }

.filtered-tiles__aside {
  float: right;
  width: 25%;
  box-sizing: border-box;
  padding-left: 50px;
  font-size: 18px; }

.check-group {
  margin: 30px 0;
  > ul {
    margin-top: 15px;
    > li {
      margin-top: 10px;
      font-size: 14px;
      letter-spacing: 0.1em; } }
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }
.partners-lumber {
  h3 {
    text-transform: uppercase;
    margin-bottom: 30px; } }
.post-nav {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: bold;
  a {
    margin-right: 20px;
    text-decoration: underline; } }
.people {
  overflow: hidden;
  font-size: 0; }
.person {
  display: inline-block;
  width: 50%;
  padding-right: 50px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 45px;
  &__pic {
    margin-bottom: 20px; }
  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0; }
  strong {
    text-transform: uppercase;
    display: block;
    padding-top: 5px;
    font-weight: normal;
    font-size: 18px; } }
.maxwidth {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;
  p {
    text-align: center !important; } }
.h1_left {
  // margin-left: 100px
  margin-bottom: 50px; }
.news-item {
  // margin-left: 100px
  margin-bottom: 70px;
  h2 {
    font-size: 24px;
    line-height: 30px; }
  &__date {
   font-style: italic;
   color: #a9a9a9;
   margin-top: 10px;
   display: block;
   margin-bottom: 20px;
   font-size: 14px; }
  &__picture {
    margin-left: -100px;
    img {
      width: 100%;
      display: block;
      height: auto; } }
  &__more {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px solid #000;
    display: inline-block;
    transition: all .3s linear;
    &:hover {
      border-color: red; } } }
.sublinks {
  margin-left: 40px;
  margin: 30px 0 30px 40px;
  width: 300px;
  @include r(1300) {
    margin-left: 0; }
  li {
    margin-bottom: 35px;
    a {
      // display: inline-block
      border-bottom: 1px solid #000;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      transition: all .3s linear;
      &:hover {
        border-color: red; } } } }
.post-nav {
  list-style: none;
  li {
    display: inline-block;
    margin-right: 20px; } }
.examples {
  clear: both;
  h2 {
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 30px; } }
.ohscrollme {
  z-index: 2;
  display: inline-block;
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  padding-top: 80px;
  position: relative;
  top: -17px;
  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb07 2s infinite;
    animation: sdb07 2s infinite;
    opacity: 0;
    box-sizing: border-box;
    &:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s; }
    &:nth-of-type(2) {
      top: 12px;
      -webkit-animation-delay: .15s;
      animation-delay: .15s; }
    &:nth-of-type(3) {
      top: 24px;
      -webkit-animation-delay: .3s;
      animation-delay: .3s; } } }

@keyframes sdb07 {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }
