.icon-arrow-right {
  width: 1em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-border {
  width: 1.17em;
  height: 1em;
  fill: none;
  stroke: #000;
}
.icon-bubles {
  width: 1.38em;
  height: 1em;
  fill: #000;
  stroke: initial;
}
.icon-call {
  width: 1em;
  height: 1em;
  fill: #000;
  stroke: initial;
}
.icon-cigarette {
  width: 1.03em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: #FFF;
  stroke: initial;
}
.icon-do {
  width: 5.15em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-dust {
  width: 1.06em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-e1 {
  width: 1em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-hint-error {
  width: 1em;
  height: 1em;
  fill: #F00;
  stroke: initial;
}
.icon-hint-info {
  width: 1em;
  height: 1em;
  fill: #ACAFB2;
  stroke: initial;
}
.icon-kg {
  width: 0.72em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-labuten {
  width: 1.25em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-leaflet {
  width: 1em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-letter-dot {
  width: 0.3em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-letter-e {
  width: 0.8em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-letter-l {
  width: 0.3em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-letter-r {
  width: 0.91em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-letter-t {
  width: 0.53em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-letter-u {
  width: 0.79em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-letter-z {
  width: 0.73em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-loader {
  width: 1.29em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-lock {
  width: 1em;
  height: 1em;
  fill: #000;
  stroke: initial;
}
.icon-loupe {
  width: 1em;
  height: 1em;
  fill: #979797;
  stroke: initial;
}
.icon-mop {
  width: 1em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-mouse {
  width: 0.64em;
  height: 1em;
  fill: #4a4a4a;
  stroke: initial;
}
.icon-play {
  width: 0.78em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-r-dot-o {
  width: 1.29em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-r-logo {
  width: 1.27em;
  height: 1em;
  fill: #ED1C23;
  stroke: initial;
}
.icon-radio {
  width: 0.51em;
  height: 1em;
  fill: #4a4a4a;
  stroke: initial;
}
.icon-rezult-logotype {
  width: 4.73em;
  height: 1em;
  fill: none;
  stroke: #000;
}
.icon-sound-0 {
  width: 1.22em;
  height: 1em;
  fill: #FFF;
  stroke: initial;
}
.icon-sound-100 {
  width: 1.05em;
  height: 1em;
  fill: #FFF;
  stroke: initial;
}
.icon-sound-50 {
  width: 0.85em;
  height: 1em;
  fill: #FFF;
  stroke: initial;
}
.icon-sound {
  width: 0.59em;
  height: 1em;
  fill: #FFF;
  stroke: initial;
}
.icon-squares {
  width: 1em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-tag-o {
  width: 0.7em;
  height: 1em;
  fill: #000;
  stroke: initial;
}
.icon-tag {
  width: 0.7em;
  height: 1em;
  fill: #000;
  stroke: initial;
}
.icon-temp {
  width: 0.83em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-wineglass {
  width: 1.11em;
  height: 1em;
  fill: #231F20;
  stroke: initial;
}
.icon-wood-log {
  width: 2.06em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-wood-samples {
  width: 0.78em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
.icon-wood {
  width: 0.78em;
  height: 1em;
  fill: #4A4A4A;
  stroke: initial;
}
