.subhero {
	background: #2c2828;
	@include clr;
	&__col {
		float: left;
		width: 50%;
		height: 619px;
		color: #fff;
		box-sizing: border-box;
		padding-top: 130px;
		text-align: center;
		padding-left: 5%;
		padding-right: 5%;
		position: relative;
		.btn {
			min-width: 230px;
			float: left;
			position: relative;
			z-index: 3;
			@include rmin(1400) {
				min-width: 280px; }
			&+.btn {
				float: right; } } }
	&__bg {
		background:  url(../img/sub1.jpg)  no-repeat 0 0;
		background-size: cover;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		opacity: .7; }
	&__video {
		margin-bottom: 30px;
		border: 1px solid #fff;
		color: #fff;
		display: block;
		text-align: center;
		min-height: 275px;
		position: relative;
		box-sizing: border-box;
		padding-top: 38px;
		text-decoration: none;
		text-transform: uppercase;
		z-index: 2;
		.icon {
			display: block;
			color: #fff;
			font-size: 46px;
			text-align: center;
			fill: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 4;
			transform: translate(-50%,-50%); }
		&:after {
		    content: "";
		    position: absolute;
		    z-index: 0;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background: rgba(255, 0, 0, 0.8);
		    box-shadow: 0 0 50px rgba(255, 0, 0, 0.6);
		    pointer-events: none;
		    @include trans;
		    opacity: 0;
		    visibility: hidden;
		    transform: translate3d(0,0,0) scale(1.15); }
		&:hover {
		    z-index: 2;
		    &:after {
		      transition-delay: 0.1s;
		      opacity: 1;
		      visibility: visible;
		      transform: translate3d(0,0,0) scale(1); } }
		span {
			position: relative;
			z-index: 2;
			font-weight: bold;
			font-size: 42px; }
		.subhero__subtitle {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 30px;
			text-align: center;
			font-size: 22px;
			font-weight: bold;
			display: block; } } }


