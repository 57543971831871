/* done by Coderiver.com.ua */
@import "vars";
@import "helpers/all";
@import "generated/sprite-svg";
@import "perfect-scrollbar/src/css/main";
@import "magnific-popup/src/css/main";
@import "inlined";
@import "lib/slick";
@import "lib/tooltipster";
@import "common";
@import "partials/hero";
@import "partials/subhero";
@import "partials/navigation";
@import "partials/modal-video";
@import "partials/footer";
@import "partials/calculator";
@import "partials/nouislider-theme";
@import "partials/gs";
@import "main";
